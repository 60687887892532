export default function selectionFilterResults(results, orderby, condensed) {
  
  const resultsArr = [];
  var index = 0;
 
  if (orderby === 'category') {
    const uniqueCategoriesArr = [... new Set(results.map(data => data.test_category))]  
    for (index = 0; index < uniqueCategoriesArr.length; index++) {
      resultsArr.push({ title: uniqueCategoriesArr[index], data: results?.filter((item) => item.test_category === uniqueCategoriesArr[index]) });
    }
  } else if (orderby === 'patient_surname') {
    const uniqueCategoriesArr = [... new Set(results.map(data => data.patient_surname))]  
    for (index = 0; index < uniqueCategoriesArr.length; index++) {
      resultsArr.push({ title: uniqueCategoriesArr[index], data: results?.filter((item) => item.patient_surname === uniqueCategoriesArr[index]) });
    }
  } else if (orderby === 'date') {
    let filteredData = [];
    // Sorting labOrders by ordered_date
    results.sort((a, b) => a.ordered_date.seconds - b.ordered_date.seconds);
    const uniqueCategoriesArr = [... new Set(results.map(data => (new Date(data.ordered_date.seconds*1000).toDateString())))];
    //uniqueOrderedDatesArr.sort((a, b) => a - b); // Sort dates in ascending order

    for (index = uniqueCategoriesArr.length - 1; index >= 0; index--) {
      filteredData = results?.filter((item) => new Date(item.ordered_date.seconds*1000).toDateString() === uniqueCategoriesArr[index]);
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      resultsArr.push({
        title: uniqueCategoriesArr[index],
        data: filteredData });
    }
  }
/*   else if (orderby === 'date') {
    const uniqueDatesArr = [... new Set(results.map(data => new Date(data.ordered_date.seconds*1000).getDay()))]
    for (index = uniqueDatesArr.length-1; index >= 0; index--) {
      resultsArr.push({ title: uniqueDatesArr[index], data: results?.filter((item) => (new Date(item.ordered_date.seconds*1000).getDay() === uniqueDatesArr[index])) });
    }
  } */
   else if (orderby === 'doctor_surname') {
    const uniqueSurnamesArr = [... new Set(results.map(data => data.doctor_surname))]  
    for (index = 0; index < uniqueSurnamesArr.length; index++) {
      resultsArr.push({ title: uniqueSurnamesArr[index], data: results?.filter((item) => item.doctor_surname === uniqueSurnamesArr[index]) });
    }
  }

  if (condensed === true) {
    for (const group of resultsArr) {
      const paymentIdGroups = group.data.reduce((groups, order) => {
        const paymentId = order.m_payment_id;
        groups[paymentId] = groups[paymentId] || [];
        groups[paymentId].push(order);  
        return groups;
      }, {}); 
    
      // Transform the grouped data
      group.data = Object.values(paymentIdGroups);
    }
}

  return {results: resultsArr};
}