import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Results, Header, Loading, Player, AllPages } from '../components';
import * as ROUTES from '../constants/routes';
import logo from '../logo.png';
import { UserContext } from '../context/UserContext';
import { FirebaseContext } from '../context';
import { SelectProfileContainer } from './profiles';
import { selectionFilterResults } from '../utils';
import { selectionFilterLabOrders } from '../utils';
import { selectionFilterPayLaterOrders } from '../utils';
import { FooterContainer } from './footer';

import { useOrders } from '../hooks';
import { useIncompleteOrders } from '../hooks';
import { usePayLaterOrders } from '../hooks';

import { useDoctors } from '../hooks';
import { usePracticeMembers } from '../hooks';
import { useLabSearch } from '../hooks';
import { useHistory } from 'react-router-dom';
import { writeLabTests } from '../utils';
import { writeToReleaseQueue } from '../utils';

import Fuse from 'fuse.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 

import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export function PracticeContainer({props}) {
  
  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.5,
    keys: ['patient_surname','patient_national_id','doctor_national_id','requisition_number','doctor_surname','name'],
  };
  const initialData = {};
  const fuse = new Fuse(initialData, options);
  
  const {reportPath, setReportPath, editProfile, setEditProfile, displayProfile, setDisplayProfile, contextPraticeMembers, setContextPracticeMembers, userData, incompleteOrderId, setIncompleteOrderId} = useContext(UserContext);
  const user = props.userData;
  const [profile, setProfile] = useState({});
  const [editProfileLoc, setEditProfileLoc] = useState({});
  const [searchedLabOrders, setSearchedLabOrders] = useState({});

  const [searchedPayLaterOrders, setSearchedPayLaterOrders] = useState({});
  const [payLaterOrdersDisplay, setPayLaterOrdersDisplay] = useState('status');
  const [searchTermPayLater, setSearchTermPayLater] = useState('');

  
  const [editMode, setEditMode] = useState(false);
  const [resultsDisplay, setResultsDisplay] = useState('date');
  const [labOrdersDisplay, setLabOrdersDisplay] = useState('status');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState({});
  const [resultRows, setResultRows] = useState({});
  const [labOrderRows, setLabOrderRows] = useState({});
  const [condensedRows, setCondensedRows] = useState({});
  const [condensedPayLaterRows, setCondensedPayLaterRows] = useState({});
  const [condensedResultRows, setCondensedResultRows] = useState({});


  const { payLaterOrders } = usePayLaterOrders(user.user_type,user.practice_id);
  const [payLaterOrderRows, setPayLaterOrderRows] = useState({});
  
  const [labTestOffering, setLabTestOffering] = useState({});
  const history = useHistory();
  
  //  const { results } = useLabResults();
  const { orders } = useOrders("practice",user.practice_id);
  const { incompleteOrders } = useIncompleteOrders(user.practice_id);
  //  const { dependants } = useDependants(user.practice_id);
  //  const { practiceMembers } = usePracticeMembers(user.practice_id);
  //  const { practiceMembers } = useContext(UserContext);

  //  const { practices } = usePractices();
  const { doctors } = useDoctors();
  const { firebase } = useContext(FirebaseContext);
  const auth = getAuth();
  const { practiceMembers } = usePracticeMembers(user.practice_id);
  const [startDate, setStartDate] = useState();
  const [practiceID, setPracticeID] = useState(user.practice_id);
  const [doctorID, setDoctorID] = useState(user.user_id);
  const [orderDoctorID, setOrderDoctorID] = useState();
  const [endDate, setEndDate] = useState();
  const [patientID, setPatientID] = useState();
  const [patientSurname, setPatientSurname] = useState();
  const [labSearch, setLabSearch] = useState(0);
  const { results } = useLabSearch(labSearch,startDate,endDate, patientID, patientSurname, practiceID, doctorID);

  const [viewCompact, setViewCompact] = useState(true);
  const [viewIncomplete, setViewIncomplete] = useState(true);
  const [viewPending, setViewPending] = useState(null);
  const [viewInProcess, setViewInProcess] = useState(null);
  const [viewResults, setViewResults] = useState(null);

  var prevPaymentId = 0;
  var gradient = 1;
  var displayInvoiceLink = false;
  

  // Results Release Countdown
  const [resultsTimer, setResultsTimer] = useState(new Date());
  // Call the function initially to display the countdown
  // updateResultsTimer();
  
  function updateResultsTimer() {
    setResultsTimer(new Date());
  }
  // Update the countdown periodically (e.g., every 5 minutes)
  setInterval(updateResultsTimer, 300 * 1000); 

  function FSTimestampToString(firestoreTimestamp) {
    // Convert Firestore timestamp to JavaScript Date
    const date = firestoreTimestamp.toDate();
    const options = {
      weekday: 'long',  // Get the full weekday name (e.g., "Monday")
      day: 'numeric',   // Get the day of the month as a number (e.g., "27")
      month: 'long',    // Get the full month name (e.g., "May")
      year: 'numeric',  // Get the full year (e.g., "2024")
      hour12: false,
      // hour: '2-digit',  // Get the hour in 24-hour format with leading zero (e.g., "14")
      // minute: '2-digit' // Get the minutes with leading zero (e.g., "00")
    };
    const formattedDate = date.toLocaleDateString("en-US", options); // Locale for day and month names
    const formattedTime = date.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false }); // Locale for time formatting
    return `${formattedDate} ${formattedTime}`;
  }

  function timestampToString(timestamp){
    const formattedString = timestamp.toLocaleString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Africa/Johannesburg" // Set the timezone
    });
    return formattedString;
  }

  //cookies and methods to remember the view options set by the user
  useEffect(() => {
    try{
      const retrievedString = localStorage.getItem('EZPathViewSettings');
      const viewSettings = JSON.parse(retrievedString) || [true, true, false, false];;

      // Set default values if no cookie is found
      setViewIncomplete(viewSettings[0]); // Default to checked
      setViewPending(viewSettings[1]);
      setViewInProcess(viewSettings[2]);
      setViewResults(viewSettings[3]);
      
        document.getElementById("cb-incomplete").checked = viewSettings[0];
        document.getElementById("cb-pending").checked = viewSettings[1];
        document.getElementById("cb-in-process").checked = viewSettings[2];
        document.getElementById("cb-results").checked = viewSettings[3];

    } catch (error) {
      console.error("An error occurred:", error); 
    }
  }, []);

  const handleIncompleteOrderClick = (event) => {
    let id  = event.target.id;
    if (id === "") {
      id = event.target.parentNode.id;
    }

    if (id !== "") {
      setIncompleteOrderId(id);
      history.push(ROUTES.ORDER_TESTS);
    }
  }

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    // Update state based on which checkbox changed
    switch (id) {
      case "cb-incomplete":
        setViewIncomplete(checked);
        break;
      case "cb-pending":
        setViewPending(checked);
        break;
      case "cb-in-process":
        setViewInProcess(checked);
        break;
      case "cb-results":
        setViewResults(checked);
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    if (viewIncomplete !== null &&  viewPending !== null && viewInProcess !== null && viewResults !== null)   { //don't store null initial values
      localStorage.setItem('EZPathViewSettings', JSON.stringify([viewIncomplete, viewPending, viewInProcess, viewResults])); 
    }
  }, [ viewIncomplete, viewResults, viewPending, viewInProcess]);
 // end of cookies to remember the view state set by the user

 /* useEffect(() => {
  if (Object.keys(incompleteOrders).length !== 0) {
    //var tempIncompleteOrders = 
  }
}, [incompleteOrders]); */


    useEffect(() => {
    if (Object.keys(practiceMembers).length !== 0) {
      setContextPracticeMembers(practiceMembers);
    }
  }, [practiceMembers]);

  useEffect (() => {
    if (orders != null) {
      if (payLaterOrders.length > 0 && searchTermPayLater.length > 2) {
          const fuse = new Fuse(payLaterOrders, { 
          keys: ['patient_name','requisition_number','test_name','patient_id','patient_surname'],
        });
        const search_results = fuse.search(searchTermPayLater).map(({item}) => item);
        if (search_results.length > 0) {
          setSearchedPayLaterOrders(search_results);
        } else {
          setSearchedPayLaterOrders({}); 
        }
      }
      else{
        setSearchedPayLaterOrders({});
      }
    }
  }, [searchTermPayLater]);


  useEffect(() => {
    if (payLaterOrders && payLaterOrders.length > 0) {
      if (Object.keys(searchedPayLaterOrders).length !== 0) {
        const pay_later_order_slides = selectionFilterPayLaterOrders(searchedPayLaterOrders, payLaterOrdersDisplay);
        setPayLaterOrderRows(pay_later_order_slides);
        const condensed_slides = selectionFilterPayLaterOrders(searchedPayLaterOrders, payLaterOrdersDisplay, true);   //todo: run this base don compactView variable
        setCondensedPayLaterRows(condensed_slides);
      } else {
        const pay_later_order_slides = selectionFilterPayLaterOrders(payLaterOrders, payLaterOrdersDisplay);
        setPayLaterOrderRows(pay_later_order_slides);
        const condensed_slides = selectionFilterPayLaterOrders(payLaterOrders, payLaterOrdersDisplay, true);   //todo: run this base don compactView variable
        setCondensedPayLaterRows(condensed_slides);
      }
    } 
  }, [payLaterOrders, searchedPayLaterOrders, payLaterOrdersDisplay, /* orderInfo */]);

  useEffect(() => {
    if (orders != null) {
      if (Object.keys(searchedLabOrders).length !== 0) {
        const lab_order_slides = selectionFilterLabOrders(searchedLabOrders, labOrdersDisplay);
        setLabOrderRows(lab_order_slides);
        const condensed_slides = selectionFilterLabOrders(searchedLabOrders, labOrdersDisplay, true);   //todo: run this base don compactView variable
        setCondensedRows(condensed_slides);
      } else {
        const lab_order_slides = selectionFilterLabOrders(orders, labOrdersDisplay);
        setLabOrderRows(lab_order_slides);
        const condensed_slides = selectionFilterLabOrders(orders, labOrdersDisplay, true);   //todo: run this base don compactView variable
        setCondensedRows(condensed_slides);
      }
    }
  }, [orders, searchedLabOrders, labOrdersDisplay]);

  // set the display profile right at the start based on the user. 
  // some GUI elements are hidden based on the user role
  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      setDisplayProfile(user);
    }
    setIncompleteOrderId(""); //else order_tests page wil lload with aorder
  }, []);


/*   useEffect(() => {
    if (Object.keys(doctors).length !== 0) {
      console.log(doctors);
    }
    if (Object.keys(practices).length !== 0) {
      console.log(practices);
    }
  }, [doctors, practices]);
 */
  useEffect(() => {
    if (Object.keys(editProfileLoc).length !== 0) {
      setEditProfile(editProfileLoc);
       history.push(ROUTES.PRACTICE_MEMBER_DETAILS);
    }
  }, [editProfileLoc]);

  useEffect(() => {
    if (results !== null && results.length > 0) {
      const result_slides = selectionFilterResults(results, resultsDisplay);
      setResultRows(result_slides);
      const condensed_slides = selectionFilterResults(results, resultsDisplay, true);   //todo: run this based on compactView variable
      setCondensedResultRows(condensed_slides);
    }
}, [results, resultsDisplay]);

  useEffect (() => {
       fuse.setCollection(orders);
       const search_results = fuse.search(searchTerm).map(({item}) => item);
      if (orders != null & orders.length > 0 && searchTerm.length > 2 && search_results.length > 0) {
        setSearchedLabOrders(search_results);
      } else {
        setSearchedLabOrders({}); 
      }
    }, [searchTerm]);


  useEffect(() => {
    console.log("profile changed", profile);
    if (Object.keys(profile).length !== 0) {
      setDisplayProfile(profile);
    }
  }, [profile]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [profile.name]);


  function releaseReportClick(requisition_number) {
      writeToReleaseQueue(requisition_number);
  }


  function cloudFileClick(path, action) {
    const storage = getStorage();
    const fileRef = ref(storage, path); 
  
    getDownloadURL(fileRef)
      .then((url) => {
        if (action === "view") {
          setReportPath(url);
          history.push(ROUTES.VIEW_REPORT);
        } else if (action === "download") {
          // Create a hidden anchor element
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', ''); // Optional: Set filename if desired 
          document.body.appendChild(link);
  
          // Trigger the download
          link.click();
  
          // Cleanup the temporary element
          link.parentNode.removeChild(link); 
        }
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  }


  function signOut() {
    try {
      // Sign out the currently authenticated user
      auth.signOut().then(() => {
        console.log('User signed out successfully');
      });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  function editProfilesClick() {
    setEditProfileLoc(user);
  }

  function LabSearchClick() {
    if ((endDate !== undefined && endDate !== null) &&
        (startDate !== undefined && startDate !== null) &&
        (practiceID !== undefined && practiceID !== null && practiceID !== "") &&
        (doctorID !== undefined && doctorID !== null && doctorID !== ""))
      setLabSearch(0);
    else
      setLabSearch(labSearch+1);
  }

  function ClearSearchClick () {
    setLabSearch(0);
    setStartDate();
    setEndDate();
    setPracticeID();
    setDoctorID();

    document.getElementById("doctor").selectedIndex = 0;
    document.getElementById("practice").selectedIndex = 0;
    document.getElementById("datepicker").startDate = "";
    document.getElementById("patientID").value = "";
    document.getElementById("patientSurname").value = "";
  }

  const handleDateChange = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

 /*  const handlePracticeChange = event => {
    let id = event.target.options[event.target.selectedIndex].id;
    setPracticeID(id);
  };
 */
  const handleDoctorChange = event => {
    let id = event.target.options[event.target.selectedIndex].id;
    setDoctorID(id);
  };

  const handleOrderDoctorChange = event => {
    let id = event.target.options[event.target.selectedIndex].id;
    setOrderDoctorID(id);
  };

  const handleSurnameChange = event => {
    let surname = event.target.value;
    setPatientSurname(surname);
  };

  const handleIDChange = event => {
    let id = event.target.value;
    setPatientID(id);
  };

  function toSentenceCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      {loading  ? <Loading src={`/images/users/${displayProfile.photoURL}.png`} /> : <Loading.ReleaseBody />}
      {editMode ? 
          <SelectProfileContainer setEditProfile={setEditProfileLoc}  user={user} setProfile={setProfile} practiceMembers={practiceMembers} editMode={editMode} setEditMode={setEditMode}/>
          :
      <>
      <Header src="joker1" dontShowOnSmallViewPort>
        <Header.Frame>
          <Header.Group>
            <Header.Logo to={ROUTES.LANDING} src={logo} alt="EZPath" />
            <Header.Button onClick={() => editProfilesClick()}>My Details</Header.Button>
            {(user.user_role == "admin" || user.user_role == "doctor")?<><Header.Button onClick={() => setEditMode(true)}>Practice Members</Header.Button>
            </> : 
            <></>} 
            <Header.ButtonLink to={ROUTES.ORDER_TESTS}>Order a Test</Header.ButtonLink>
          </Header.Group>
          <Header.Group>
            <Header.Profile>
              <Header.Picture src={displayProfile.photoURL} onClick={() => editProfilesClick()}/>
              <Header.TextLink onClick={() => editProfilesClick()}>{displayProfile.name} </Header.TextLink>
              <Header.Dropdown>
                {/* <Header.TextLink >Change Member</Header.TextLink> */}
                { 
                  user.name === displayProfile.name ? 
                    null
                    :<Header.Group onClick={() => setProfile(user)}>
                      <Header.Picture src={user.photoURL} />
                      <Header.TextLink>{user.name}</Header.TextLink>
                    </Header.Group>
                 }
                {/* { {practiceMembers.map((item) => (
                  item.displayName === displayProfile.displayName?
                  null
                  :<Header.Group key={item.user_id} onClick={() => setProfile(item)}>
                  <Header.Picture src={item.photoURL} 
                    data-testid="user-profile"/>
                  <Header.TextLink>{item.displayName}</Header.TextLink>
                </Header.Group>
                ))}} */}
                <Header.Group>
                <Header.Button onClick={() => signOut()}>Sign Out</Header.Button> 
                </Header.Group>
              </Header.Dropdown>
            </Header.Profile>
          </Header.Group>
        </Header.Frame>

        <Header.Feature>
          <Header.FeatureCallOut>Practice Home</Header.FeatureCallOut>
          <Header.Text>
            Welcome to EZPath Practice where you can manage your Practice's interaction with patients and labs.
          </Header.Text>
          <Results.LongContainer>
           <Results.SubTitle>VIEW OPTIONS:    </Results.SubTitle>
          {/* <Results.CheckBox id={"cb-compact"} onChange={handleCheckboxChange}></Results.CheckBox>
          <Results.SubTitle>Compact View</Results.SubTitle>
          <Results.ButtonSpacer></Results.ButtonSpacer> */}
          <Results.CheckBox id={"cb-incomplete"} onChange={handleCheckboxChange}></Results.CheckBox>
          <Results.SubTitle>Incomplete Orders</Results.SubTitle>
          <Results.ButtonSpacer></Results.ButtonSpacer>
          <Results.CheckBox id={"cb-pending"} onChange={handleCheckboxChange}></Results.CheckBox>
          <Results.SubTitle>Pending Tests</Results.SubTitle>
          <Results.ButtonSpacer></Results.ButtonSpacer>
          <Results.CheckBox id={"cb-in-process"} onChange={handleCheckboxChange} defaultChecked={viewInProcess}></Results.CheckBox>
          <Results.SubTitle>Tests In Process</Results.SubTitle>
          <Results.ButtonSpacer></Results.ButtonSpacer>
          <Results.CheckBox id={"cb-results"} onChange={(e) => setViewResults(e.target.checked)} defaultChecked={viewResults}></Results.CheckBox>
          <Results.SubTitle>Results</Results.SubTitle>
      </Results.LongContainer>
        </Header.Feature>
      </Header>

  { viewIncomplete === true? 
    <>
      <Results.Group>
        <Results.Heading>Incomplete Orders</Results.Heading>
        { Object.keys(incompleteOrders).length === 0 ? 
            <Results>
              <Results.Entities>
              <Results.Item gradient = {gradient}>
                <Results.SubTitle>No Incomplete Orders</Results.SubTitle>
              </Results.Item>
              </Results.Entities>
            </Results> 
            :  
          <>
          <Results>
            <Results.Entities>
            {incompleteOrders.map((order) => (
                  <Results.Item id={order.id}  gradient = {gradient} onClick={(event) => handleIncompleteOrderClick(event)}> 
                    <Results.SubTitle>Name: {order.orderInfo.name} {order.orderInfo.surname}</Results.SubTitle>
                    <Results.SubTitle>ID: {order.orderInfo.national_id}</Results.SubTitle>
                    <Results.SubTitle>{toSentenceCase(user.user_profession)}: {order.orderInfo.doctors_first_name} {order.orderInfo.doctors_surname}</Results.SubTitle>
                    { <Results.SubTitle>{FSTimestampToString(order.orderInfo.timestamp)}</Results.SubTitle> }
                  </Results.Item>
            ))}
            </Results.Entities>
            </Results>
          </>
        }
      </Results.Group>
      </>:
      <></>
  }
    { viewPending === true ?
    <>
    { viewCompact === true ? 
      <>
        <Results.Group>
          {
            <>
              <Results.ButtonContainer>
                <Results.Heading>Pending Tests</Results.Heading> { /*{displayProfile.displayName}'*/ /*todo put in Lan Name from DB)*/}
                <Header.Search searchTerm={searchTermPayLater} setSearchTerm={setSearchTermPayLater} placeHolder="Test Name, Name, ID, Requisition" />
                <Results.SubTitle>Arrange By:</Results.SubTitle>
                <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('status')}>Order Status</Results.ActionItem>
                <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('test_category')}>Category</Results.ActionItem>
                <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('ordered_date')}>Date</Results.ActionItem>
                <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('requisition_number')}>Requisition Number</Results.ActionItem>
              </Results.ButtonContainer>
            </>
          }
        { Object.keys(condensedPayLaterRows).length === 0 ? 
            <Results>
              <Results.Entities>
              <Results.Item gradient = {gradient}>
                <Results.SubTitle>No Pending Tests</Results.SubTitle>
              </Results.Item>
              </Results.Entities>
            </Results> 
            :  
            <>
              {condensedPayLaterRows.payLaterOrders.map((slideItem, index) => (
                
                  <Results key={index}> 
                    <Results.Title>{slideItem.title}</Results.Title>
                    <Results.Entities>
                    {slideItem.data.length === 0 ? null : (
                
                      <>
                          {slideItem.data.map((item) => ( 
                            <React.Fragment key={item[0].docId}>
                              <Results.Item gradient = {gradient}  {...gradient = gradient + 1}>
                              <Results.SubTitle>{item[0].patient_name + " " + item[0].patient_surname}</Results.SubTitle> 
                              { (item[0].status=='dispatched' && item.phlebotomy_site !== "4")?
                                <>
                                  <Results.DividingLine></Results.DividingLine>
                                  <Results.SubTitle>Status: Dispatched</Results.SubTitle>
                                  <Results.Text>EZPath has assigned a phlebotomist to assist the patient.</Results.Text>
                                  <Results.Text>The patient must visit their selected venue for sample collection.</Results.Text>
                                </>: null
                          }
                        { (item[0].status=='dispatched' && item.phlebotomy_site === "4")?
                          <>
                            <Results.DividingLine></Results.DividingLine>
                            <Results.SubTitle>Status: Dispatched</Results.SubTitle>
                            <Results.Text>EZPath has assigned a mobile phlebotomist to collect the sample.</Results.Text>
                            <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                          </> : null
                        }
                        { (item[0].status=='sampled')?
                          <>
                            <Results.DividingLine></Results.DividingLine>
                            <Results.SubTitle>Status: Sampled</Results.SubTitle>
                            <Results.Text>The sample has been collected.</Results.Text>
                            <Results.Text>It is on its way to the lab.</Results.Text>
                        </> : null
                        }
                        { (item[0].status=='ordered' && item.phlebotomy_site !== "4") ?
                          <>
                            <Results.DividingLine></Results.DividingLine>
                            <Results.SubTitle>Status: Ordered</Results.SubTitle>
                            <Results.Text>The patient will visit their selected venue for sample collection.</Results.Text>
                            <Results.Text>They must remember a card for payment.</Results.Text>
                            {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText> */}
                            </> : null
                        }
                        { (item[0].status=='ordered' && item.phlebotomy_site === "4") ?
                          <>
                            <Results.DividingLine></Results.DividingLine>
                            <Results.SubTitle>Status: Ordered</Results.SubTitle>
                            <Results.Text>EZPath will assign a mobile phlebotomist to collect the patient's sample.</Results.Text>
                            <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                            {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText></>*/}
                          </> : null
                        }
                        { (item[0].status=='received') ?
                          <>
                            <Results.DividingLine></Results.DividingLine>
                            <Results.SubTitle>Status: Received</Results.SubTitle>
                            <Results.Text>The sample is safely at the lab.</Results.Text>
                            <Results.Text>EZPath will let you know when the results are ready.</Results.Text>
                          </> : null
                        }
                        <Results.Text> Ordered Date: {new Date(item[0].ordered_date.seconds*1000).toDateString()}</Results.Text>
                              
                              {item.map((test, index) => ( 
                                <Results.TestLine key = {index}>{test.test_name}</Results.TestLine>
                              ))}
                              </Results.Item>
                            </React.Fragment>
                          ))}
                    </>
                    )}
                    </Results.Entities>
                    </Results>
                  ))}
              </>
            }
        </Results.Group>
      </>
      :
    <>
      <Results.Group>
        {<><Results.ButtonContainer>
          <Results.Heading>Pending Tests</Results.Heading> { /*{displayProfile.displayName}'*/ /*todo put in Lan Name from DB)*/}
          <Header.Search searchTerm={searchTermPayLater} setSearchTerm={setSearchTermPayLater} placeHolder="Test Name, Name, ID, Requisition" />
          <Results.SubTitle>Arrange By:</Results.SubTitle>
          <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('status')}>Order Status</Results.ActionItem>
          <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('test_category')}>Category</Results.ActionItem>
          <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('ordered_date')}>Date</Results.ActionItem>
          <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('requisition_number')}>Requisition Number</Results.ActionItem>
        </Results.ButtonContainer>
        </>}

        { Object.keys(payLaterOrderRows).length === 0 ? 
        <Results>
          <Results.Entities>
          <Results.Item gradient = {gradient}>
          <Results.SubTitle>No Pending Tests</Results.SubTitle> 
          </Results.Item>
          </Results.Entities>
        </Results> :  
        <>
        <Results.SubTitle>The order form has been completed and we are waiting for the sample to get taken.</Results.SubTitle>
          {payLaterOrderRows.payLaterOrders.map((slideItem) => (
            <Results id={displayProfile.user_id} key={`${displayProfile.user_id}-${slideItem.title}`}>
               {slideItem.data.length === 0? null : (
                <>
                <Results.Title>{slideItem.title}</Results.Title>
                <Results.Entities>
                {slideItem.data.map((item) => {
                    if (item.m_payment_id !== prevPaymentId) {
                      prevPaymentId = item.m_payment_id;
                      gradient = gradient + 1;
                      displayInvoiceLink = true;
                    }             
                    else
                    {displayInvoiceLink = false;}
                  return (
                    <Results.Item key={item.docId} id={item.docId} key={item.docId} gradient = {gradient}/* onClick={(event) => handlePayLaterItemClick(event, item)} */>  {/*item={item} href={item.test_report_path}*/}
                    <Results.SubTitle>{item.test_name}</Results.SubTitle>
    
                  <Results.SubTitle>{item.patient_name} {item.patient_surname}</Results.SubTitle>
                    <Results.SubTitle>STATUS: {item.status}</Results.SubTitle>
                    <Results.SubTitle>Category: {item.test_category}</Results.SubTitle>
                    <Results.Text>Where: {item.phlebotomy_site_name ? item.phlebotomy_site_name : "not specified"}</Results.Text>
                    {item.requisition_number? <Results.Text>Requisition: {item.requisition_number}</Results.Text>:null}
                    {/* <Results.Text>Practice:  {item.doctors_practice_name}</Results.Text> */}
                    <Results.Text>{toSentenceCase(user.user_profession)}:  {item.doctors_first_name} {item.doctors_surname}</Results.Text>
                    {(item.ordered_by_name &&  item.ordered_by_surname )?
                      <Results.Text>Ordered By:  {item.ordered_by_name + " " + item.ordered_by_surname}</Results.Text>:
                    <Results.Text>Ordered By: unknown</Results.Text>}
                    <Results.Text> Ordered Date: {new Date(item.ordered_date.seconds*1000).toDateString()}
                    </Results.Text>
                    {(displayInvoiceLink === true && item.invoice_path !== "") ?
                        <Results.Button onClick={() => cloudFileClick(item.invoice_path, "view")}>View Invoice</Results.Button>
                        :
                        null
                    }
                    { (item.status=='dispatched' && item.phlebotomy_site !== "4" && displayInvoiceLink === true)?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>EZPath has assigned a phlebotomist to assist the patient.</Results.Text>
                        <Results.Text>The patient must visit their selected venue for sample collection.</Results.Text>
                      </>: null
                    }
                    { (item.status=='dispatched' && item.phlebotomy_site === "4" && displayInvoiceLink === true)?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>EZPath has assigned a mobile phlebotomist to collect the sample.</Results.Text>
                        <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                      </> : null
                    }
                    { (item.status=='sampled' && displayInvoiceLink === true)?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>The sample has been collected.</Results.Text>
                        <Results.Text>It is on its way to the lab.</Results.Text>
                    </> : null
                    }
                    { (item.status=='ordered' && item.phlebotomy_site !== "4" && displayInvoiceLink === true) ?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>The patient will visit their selected venue for sample collection.</Results.Text>
                        <Results.Text>They must remember a card for payment.</Results.Text>
                        {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText> */}
                        </> : null
                    }
                    { (item.status=='ordered' && item.phlebotomy_site === "4" && displayInvoiceLink === true) ?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>EZPath will assign a mobile phlebotomist to collect the patient's sample.</Results.Text>
                        <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                        {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText></>*/}
                      </> : null
                    }
                  </Results.Item>
                  );
                })}
                </Results.Entities>
                </>
               )}
               </Results>
          ))}
        </>
        }
      </Results.Group>
      </>
      }
      </>
      :
      <></>
      }


        { viewInProcess === true ?
        <>
        { viewCompact === true ?
        <>
            {/* NEW ROLLUP TEST */}

      <Results.Group>
        {<><Results.LongContainer>
          <Results.Heading>Tests in Process</Results.Heading>
          <Header.Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeHolder="Test / Surname / ID / Req." />
          {/* <Results.SubTitle>Show For:</Results.SubTitle>
          <Results.Select  onChange={handleOrderDoctorChange} name="doctor" id="doctor">
            <option id = "all-selected" >Whole Practice</option>
            { typeof doctors == "undefined"  ? <></> : <>
            {doctors.map((doctor_item) => (
              <>
                <option id = {doctor_item.user_id}>{doctor_item.displayName+" "+doctor_item.surname}</option>
              </>))}
            </>}
          </Results.Select> */}
          <Results.SubTitle>Arrange By:</Results.SubTitle>
          <Results.ActionItem onClick={() => setLabOrdersDisplay('doctor_surname')}>{toSentenceCase(user.user_profession)}</Results.ActionItem>
          <Results.ActionItem onClick={() => setLabOrdersDisplay('status')}>Order Status</Results.ActionItem>
          <Results.ActionItem onClick={() => setLabOrdersDisplay('category')}>Category</Results.ActionItem>
          <Results.ActionItem onClick={() => setLabOrdersDisplay('ordered_date')}>Date</Results.ActionItem>
        </Results.LongContainer>
        </>}
       { Object.keys(condensedRows).length === 0 ? 
        <Results>
          <Results.Entities>
          <Results.Item gradient = {gradient}>
            <Results.SubTitle>No Orders for {displayProfile.displayName}</Results.SubTitle>
          </Results.Item>
          </Results.Entities>
        </Results> :  
        <>
          {condensedRows.labOrders.map((slideItem, index) => (
              <Results key={index}> 
                <Results.Title>{slideItem.title}</Results.Title>
                <Results.Entities>
                {slideItem.data.length === 0 ? null : (
             
                  <>
                      {slideItem.data.map((item) => ( 
                        
                        <>
                          <Results.Item gradient = {gradient}  {...gradient = gradient + 1}>
                          <Results.SubTitle>{item[0].patient_name + " " + item[0].patient_surname}</Results.SubTitle> 
                          { (item[0].status=='dispatched' && item.phlebotomy_site !== "4")?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.SubTitle>Status: Dispatched</Results.SubTitle>
                        <Results.Text>EZPath has assigned a phlebotomist to assist the patient.</Results.Text>
                        <Results.Text>The patient must visit their selected venue for sample collection.</Results.Text>
                      </>: null
                    }
                    { (item[0].status=='dispatched' && item.phlebotomy_site === "4")?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.SubTitle>Status: Dispatched</Results.SubTitle>
                        <Results.Text>EZPath has assigned a mobile phlebotomist to collect the sample.</Results.Text>
                        <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                      </> : null
                    }
                    { (item[0].status=='sampled')?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.SubTitle>Status: Sampled</Results.SubTitle>
                        <Results.Text>The sample has been collected.</Results.Text>
                        <Results.Text>It is on its way to the lab.</Results.Text>
                    </> : null
                    }
                    { (item[0].status=='ordered' && item.phlebotomy_site !== "4") ?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.SubTitle>Status: Ordered</Results.SubTitle>
                        <Results.Text>The patient will visit their selected venue for sample collection.</Results.Text>
                        <Results.Text>They must remember a card for payment.</Results.Text>
                        {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText> */}
                        </> : null
                    }
                    { (item[0].status=='ordered' && item.phlebotomy_site === "4") ?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.SubTitle>Status: Ordered</Results.SubTitle>
                        <Results.Text>EZPath will assign a mobile phlebotomist to collect the patient's sample.</Results.Text>
                        <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                        {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText></>*/}
                      </> : null
                    }
                    { (item[0].status=='received') ?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.SubTitle>Status: Received</Results.SubTitle>
                        <Results.Text>The sample is safely at the lab.</Results.Text>
                        <Results.Text>EZPath will let you know when the results are ready.</Results.Text>
                      </> : null
                    }
                    <Results.Text> Ordered Date: {new Date(item[0].ordered_date.seconds*1000).toDateString()}</Results.Text>
                          
                          {item.map((test, index) => ( 
                            <Results.TestLine key={index}>{test.test_name}</Results.TestLine>
                          ))}
                          </Results.Item>
                        </>
                      ))}
                </>
                )}
                </Results.Entities>
                </Results>
              ))}
        </>
        }
      </Results.Group>
        </>
        :
        <>
      <Results.Group>
        {<><Results.LongContainer>
          <Results.Heading>Tests in Process</Results.Heading>
          <Header.Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeHolder="Test / Surname / ID / Req." />
          {/* <Results.SubTitle>Show For:</Results.SubTitle>
          <Results.Select  onChange={handleOrderDoctorChange} name="doctor" id="doctor">
            <option id = "all-selected" >Whole Practice</option>
            { typeof doctors == "undefined"  ? <></> : <>
            {doctors.map((doctor_item) => (
              <>
                <option id = {doctor_item.user_id}>{doctor_item.displayName+" "+doctor_item.surname}</option>
              </>))}
            </>}
          </Results.Select> */}
          <Results.SubTitle>Arrange By:</Results.SubTitle>
          <Results.ActionItem onClick={() => setLabOrdersDisplay('doctor_surname')}>{toSentenceCase(user.user_profession)}</Results.ActionItem>
          <Results.ActionItem onClick={() => setLabOrdersDisplay('status')}>Order Status</Results.ActionItem>
          <Results.ActionItem onClick={() => setLabOrdersDisplay('category')}>Category</Results.ActionItem>
          <Results.ActionItem onClick={() => setLabOrdersDisplay('ordered_date')}>Date</Results.ActionItem>
        </Results.LongContainer>
        </>}
       { Object.keys(labOrderRows).length === 0 ? 
        <Results>
          <Results.Entities>
          <Results.Item gradient = {gradient}>
          <Results.SubTitle>No Orders for {displayProfile.displayName}</Results.SubTitle>
          </Results.Item>
          </Results.Entities>
        </Results> :  
        <>
          {labOrderRows.labOrders.map((slideItem) => (
            <Results id={displayProfile.user_id} key={`${displayProfile.user_id}-${slideItem.title}`}>
               {slideItem.data.length === 0? null : (
                <>
                <Results.Title>{slideItem.title}</Results.Title>
                <Results.Entities>
                {slideItem.data.map((item) => {
                    if (item.m_payment_id !== prevPaymentId) {
                      prevPaymentId = item.m_payment_id;
                      gradient = gradient + 1;
                      displayInvoiceLink = true;
                    }             
                    else
                    {displayInvoiceLink = false;}            
                  return (
                    <Results.Item id={item.docId} key={item.docId} gradient = {gradient}/* onClick={(event) => handlePayLaterItemClick(event, item)} */>  {/*item={item} href={item.test_report_path}*/}
                    <Results.SubTitle>{item.test_name}</Results.SubTitle>
    
                  <Results.SubTitle>{item.patient_name} {item.patient_surname}</Results.SubTitle>
                    <Results.SubTitle>STATUS: {item.status}</Results.SubTitle>
                    <Results.SubTitle>Category: {item.test_category}</Results.SubTitle>
                    <Results.Text>Where: {item.phlebotomy_site_name ? item.phlebotomy_site_name : "not specified"}</Results.Text>
                    {item.requisition_number? <Results.Text>Requisition: {item.requisition_number}</Results.Text>:null}
                    {/* <Results.Text>Practice:  {item.doctors_practice_name}</Results.Text> */}
                    <Results.Text>{toSentenceCase(user.user_profession)}:  {item.doctors_first_name} {item.doctors_surname}</Results.Text>
                    {item.ordered_by ? 
                      <Results.Text>Ordered By:  {item.ordered_by}</Results.Text>:
                    <Results.Text>Ordered By: unknown</Results.Text>}
                    <Results.Text> Ordered Date: {new Date(item.ordered_date.seconds*1000).toDateString()}
                    </Results.Text>
                    { (displayInvoiceLink === true && item.invoice_path !== "") ?
                        <Results.Button onClick={() => cloudFileClick(item.invoice_path, "view")}>View Invoice</Results.Button>
                        :
                        null
                    }
                    { (item.status=='dispatched' && item.phlebotomy_site !== "4" && displayInvoiceLink === true)?
                      <>
                      <Results.DividingLine></Results.DividingLine>
                      <Results.Text>EZPath has assigned a phlebotomist to collect your sample.</Results.Text>
                      </> : null
                    }
                    { (item.status=='dispatched' && item.phlebotomy_site === "4" && displayInvoiceLink === true)?
                      <><Results.Text>EZPath has assigned a mobile phlebotomist to collect your sample. They will contact you shortly to arrange.</Results.Text> </>:<></>
                    }
                    { (item.status=='ordered' && item.phlebotomy_site !== "4" && displayInvoiceLink === true) ?
                    <>
                      <Results.DividingLine></Results.DividingLine>
                      <Results.Text>Please visit your selected venue for sample collection.</Results.Text>
                      {/* <Results.ActionText id = {item.m_payment_id} onClick={discardOrderClick}>...cancel this order</Results.ActionText> */}
                      </> : null
                    }
                    { (item.status=='ordered' && item.phlebotomy_site === "4" && displayInvoiceLink === true) ?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>EZPath will assign a mobile phlebotomist to collect your sample.</Results.Text>
                        <Results.Text>They will contact you shortly to arrange.</Results.Text>
                        {/* <Results.ActionText id = {item.m_payment_id} onClick={discardOrderClick}>...cancel this order</Results.ActionText> */}
                        </> : null
                    }
                    { (item.status=='sampled' && displayInvoiceLink === true)?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>Your sample has been collected.</Results.Text>
                        <Results.Text>It is on its way to the lab.</Results.Text>
                    </> : null
                    }
                    { item.status=='received' && displayInvoiceLink === true?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>Your sample is safely at the lab.</Results.Text>
                        <Results.Text>We will let you know when your results are ready.</Results.Text>
                      </> : null
                    }
                  </Results.Item>
                  );
                })}
                </Results.Entities>
                </>
               )}
               </Results>
          ))}
        </>
        }
      </Results.Group>
      </>
      }
      </>
      :
      <></>
      }

      { viewResults === true ? 
      <>
      <Results.Group>
        {<><Results.LongContainer>
          <Results.Heading>Test Results</Results.Heading>
          <Results.SubTitle>Arrange By:</Results.SubTitle>
          <Results.ActionItem onClick={() => setResultsDisplay('doctor_surname')}>{toSentenceCase(user.user_profession)}</Results.ActionItem>
          <Results.ActionItem onClick={() => setResultsDisplay('patient_surname')}>Patient Surname</Results.ActionItem>
          <Results.ActionItem onClick={() => setResultsDisplay('date')}>Date</Results.ActionItem>
        </Results.LongContainer>
        <Results.LongContainer>
          <Results.SubTitle>Search By:</Results.SubTitle>
          <DatePicker id="datepicker" selected={startDate}  placeholderText={'Test Date Range'}
            onChange={handleDateChange} startDate={startDate} endDate={endDate} selectsRange isClearable
          />
          <Results.Select  onChange={handleDoctorChange} name="doctor" id="doctor">
            <option id = "none-selected" >Select a {user.user_profession}</option>
            { typeof practiceMembers == "undefined"  ? <></> :
              <>
                { practiceMembers.map((practice_item) => (
                <>
                {practice_item.user_role == "doctor" ? <option key = {practice_item.user_id} id = {practice_item.user_id}>{practice_item.name+" "+practice_item.surname}</option>
                :<></>}    
                </>
              ))}
            </>}

          </Results.Select>
          <Results.Input id="patientID" placeholder="Patient ID" defaultValue={""} onChange={handleIDChange}/>
          <Results.Input id="patientSurname" placeholder="Patient Surname" defaultValue={""} onChange={handleSurnameChange}/>
        </Results.LongContainer>
        <Results.LongContainer>
          <Results.ActionItem onClick={() => LabSearchClick()}>Search Now</Results.ActionItem>
          <Results.ActionItem onClick={() => ClearSearchClick()}>Clear Search</Results.ActionItem>
        </Results.LongContainer>
        </>}
        { condensedResultRows === null || condensedResultRows === undefined || Object.keys(condensedResultRows).length === 0 ? 
                  <>
                    <Results>
                      <Results.Entities>
                      <Results.Item gradient = {gradient}>
                        <Results.SubTitle>No Compact Results Available</Results.SubTitle>
                      </Results.Item>
                      </Results.Entities>
                    </Results>
                  </> 
                  :  
                  <>
  {condensedResultRows.results.map((slideItem, index) => (
    <Results key={index}>
      <Results.Title>{slideItem.title}</Results.Title>
      <Results.Entities>
        {slideItem.data.length === 0 ? null : (
          <>
            {slideItem.data.map((item) => (
              <Results.Item
                id={item.docId}
                key={item.docId}
                gradient={gradient}
                {...(gradient = gradient + 1)}
              >
                <Results.SubTitle>{item[0].patient_name} {item[0].patient_surname}</Results.SubTitle>
                <Results.SubTitle>{item[0].patient_national_id}</Results.SubTitle>
                <Results.DividingLine />
                <Results.Text>{toSentenceCase(user.user_profession)}: {item[0].doctors_first_name} {item[0].doctors_surname}</Results.Text>
                <Results.Text>Ordered Date: {new Date(item[0].ordered_date.seconds * 1000).toDateString()}</Results.Text>
                <Results.Text>Collection Site: {item[0].phlebotomy_site_name}</Results.Text>

                {/* todo: only enable download and view buttons if report actually exists in database */}
                {item.map((test, index) => (
                  <Results.TestLine key={index}>{test.test_name}</Results.TestLine>
                ))}

                {item[0].batch_normal === "true" ? (
                  <>
                    <Results.Text>All results in normal range</Results.Text>
                    <Results.Text>
                        {(() => {
                          if (item[0].released_to_patient && item[0].released_to_patient === "true") {
                            return "Results were released to patient";
                          } else if (item[0].released_to_patient && item[0].released_to_patient === "pending") {
                            return "Results release in progress";
                          } else if (item[0].released_to_patient && item[0].released_to_patient === "disabled") {
                            return "Auto-release disabled by doctor";
                          }
                        })}
                      </Results.Text>
                      {/* final results available and not released by doctor */}
                    {item[0].final_results_time && item[0].released_by && item[0].released_by !== "doctor" ? (
                      <>
                      <Results.Text>
                        {(() => {
                          const resultsAvailableDate = item[0].final_results_time.toDate();
                          const now = new Date();
                          resultsAvailableDate.setHours(resultsAvailableDate.getHours() + parseInt(item[0].release_normal_period,10));
                          const timeDifferenceMs = resultsAvailableDate - now;
                          if (timeDifferenceMs > 0) {
                            const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
                            const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));
                            return `${hours}:${minutes} until auto-release`;
                          }
                        })()}
                      </Results.Text>
                      <Results.Button 
                      onClick={() => releaseReportClick(item[0].requisition_number)}>
                        Release to Patient</Results.Button>
                        </>
                    ) : (
                      <></>
                    )}
                    {item[0].final_results_time && item[0].released_by && item[0].released_by === "doctor" && item[0].released_date ? 
                      <Results.Text>Released by {user.user_profession} on {new Date(item[0].released_date.seconds*1000).toDateString()}</Results.Text>
                      : <></>}
                  </>
                ) : (
                  <>
                    <Results.Text>! Abnormal results present</Results.Text>
                    <Results.Text>  Auto-release disabled</Results.Text>
                    { (item[0].released_to_patient && item[0].released_to_patient === "true")?
                        <Results.Text>Results were released to patient</Results.Text>
                        : <></>}
                    {(item[0].released_to_patient && item[0].released_to_patient === "pending")? 
                      <Results.Text>Results release in progress</Results.Text>
                      : <></>}
                    {(item[0].released_by)? 
                      <Results.Text>Released by: {item[0].released_by}</Results.Text>
                      : <></>}
                    {(item[0].released_date)? 
                      <Results.Text>Released Date: {FSTimestampToString(item[0].released_date)}</Results.Text>
                      : <></>}
                  </>
                )}

                {item[0].final_report_path && (!item[0].released_to_patient || item[0].released_to_patient === "disabled") ? (
                  <Results.Button 
                  onClick={() => releaseReportClick(item[0].requisition_number)}>
                    Release to Patient</Results.Button>
                ) : null}

                {/* <Results.ButtonLink to={ ROUTES.VIEW_REPORT }>View Report</Results.ButtonLink> */}

                {!item[0].final_report_path && item[0].prelim_report_path ? (
                  <>
                    <Results.ButtonDownload
                      onClick={() => cloudFileClick(item[0].prelim_report_path, "view")}
                    >
                      View Preliminary Report
                    </Results.ButtonDownload>
                    <Results.ButtonDownload
                      onClick={() => cloudFileClick(item[0].prelim_report_path, "download")}
                    >
                      Download Preliminary Report
                    </Results.ButtonDownload>
                  </>
                ) : null}

                {item[0].final_report_path ? (
                  <>
                    <Results.ButtonDownload
                      onClick={() => cloudFileClick(item[0].final_report_path, "view")}
                    >
                      View Final Report
                    </Results.ButtonDownload>
                    <Results.ButtonDownload
                      onClick={() => cloudFileClick(item[0].final_report_path, "download")}
                    >
                      Download Final Report
                    </Results.ButtonDownload>
                  </>
                ) : null}

                {item.cumulative_report_path ? (
                  <>
                    <Results.ButtonDownload
                      onClick={() => cloudFileClick(item[0].cumulative_report_path, "view")}
                    >
                      View Cumulative Report
                    </Results.ButtonDownload>
                    <Results.ButtonDownload
                      onClick={() => cloudFileClick(item[0].cumulative_report_path, "download")}
                    >
                      Download Cumulative Report
                    </Results.ButtonDownload>
                  </>
                ) : null}
              </Results.Item>
            ))}
          </>
        )}
      </Results.Entities>
    </Results>
  ))}
</>
                } 
      </Results.Group> 
      </>
      :
      <></>
      }

      <FooterContainer />
    </>
    }
    </>
  //) 
  //: (
  //  <SelectProfileContainer setEditProfile={setEditProfileLoc} user={user} setProfile={setProfile} editMode={editMode} dependants={dependants} />
  );
}