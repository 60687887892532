import styled from 'styled-components/macro';

//export const Container = styled.div`
//  display: flex;
//  border-bottom: 8px solid #222;
//`;

export const AddButton = styled.div`
  display: block;
  background: #303030;
  height: 35px;
  width: 35px;
  margin-left: auto;
  text-align: center;
  border: 0;
  font-size: 32px;
  text-decoration: bold;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #909033;;
  }
`;

export const RemoveButton = styled.div`
  display: block;
  background: #303030;
  flex-shrink: 0;
  width: 35px;
  text-align: center;
  margin-left: auto;
  border: 0;
  font-size: 32px;
  text-decoration: bold;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #909033;;
  }
`;

export const TariffInput = styled.input`
  margin-right: 1px;
  height: 35px;
  width: 45px;
  text-align: center;
  align: left;
  color: #fff;
  display: flex;
  text-align: left;
  background: #303030;
  border-radius: 4px;
  border: 0;
`;

export const LineWrapper = styled.div`
  display: flex;
  align-items: center; 
  margin-bottom: 2px;


`;

export const NameInput = styled.input`
  margin-right: 1px;
  height: 35px;
  width: 100%;
  text-align: center;
  color: #fff;
  align: left;
  display: flex;
  text-align: left;
  background: #303030;
  border-radius: 4px;
  border: 0;
`;

export const CategoryInput = styled.input`
  margin-right: 1px;
  height: 35px;
  width: 100%;
  text-align: center;
  align: left;
  display: flex;
  text-align: left;
  background: #303030;
  color: #fff;
  border-radius: 4px;
  border: 0;
`;

export const PriceInput = styled.input`
  margin-right: 1px;
  height: 35px;
  width: 60px;
  text-align: right;
  display: flex;
  background: #303030;
  color: #fff;
  border-radius: 4px;
  border: 0;
`;


export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px; 
`;