import React from 'react';
import { Container, PaddedContainer, OptionButton, LongContainer, LongLeftContainer, DividingLine, Error, Base, Title, Text, TextSmall, SectionHeader,
  OptionText, TextLeft, Link, Input, InputNarrow, Button, BackButton, TextRight, Space, CheckBox, RadioBox, Body, SubButton, Heading1, Caption, EmphasisLine,
  GridContainer, GridColumn, GridText, ExpandingContainer, DappledText, LeftText} from './styles/form';

export default function Form({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Form.Error = function FormError({ children, ...restProps }) {
  return <Error {...restProps}>{children}</Error>;
};

Form.GridContainer = function FormGridContainer({ children, ...restProps }) {
  return <GridContainer {...restProps}>{children}</GridContainer>;
};

Form.LeftText = function FormLeftText({ children, ...restProps }) {
  return <LeftText {...restProps}>{children}</LeftText>;
};

Form.GridColumn = function FormGridColumn({ children, ...restProps }) {
  return <GridColumn {...restProps}>{children}</GridColumn>;
};

Form.EmphasisLine = function FormEmphasisLine({ children, ...restProps }) {
  return <EmphasisLine {...restProps}>{children}</EmphasisLine>;
};

Form.PaddedContainer = function FormPaddedContainer({ children, ...restProps }) {
  return <PaddedContainer {...restProps}>{children}</PaddedContainer>;
};

Form.LongContainer = function FormLongContainer({ children, ...restProps }) {
  return <LongContainer {...restProps}>{children}</LongContainer>;
};

Form.ExpandingContainer = function FormExpandingContainer({ children, ...restProps }) {
  return <ExpandingContainer {...restProps}>{children}</ExpandingContainer>;
};

Form.LongLeftContainer = function FormLongLeftContainer({ children, ...restProps }) {
  return <LongLeftContainer {...restProps}>{children}</LongLeftContainer>;
};

Form.Base = function FormBase({ children, ...restProps }) {
  return <Base {...restProps}>{children}</Base>;
};

Form.Title = function FormTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Form.Heading1 = function FormHeading1({ children, ...restProps }) {
  return <Heading1 {...restProps}>{children}</Heading1>;
};

Form.Text = function FormText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Form.DappledText = function FormDappledText({ children, ...restProps }) {
  return <DappledText {...restProps}>{children}</DappledText>;
};

Form.GridText = function FormGridText({ children, width, ...restProps }) {
  return <GridText style={{ width }} {...restProps}>{children}</GridText>;
};

Form.Caption = function FormCaption({ children, ...restProps }) {
  return <Caption {...restProps}>{children}</Caption>;
};

Form.Space = function FormSpace({ children, ...restProps }) {
  return <Space {...restProps}>{children}</Space>;
};

Form.DividingLine = function FormDividingLine({ children, ...restProps }) {
  return <DividingLine {...restProps}>{children}</DividingLine>;
};

Form.TextRight = function FormTextRight({ children, ...restProps }) {
  return <TextRight {...restProps}>{children}</TextRight>;
};

Form.TextLeft = function FormTextLeft({ children, ...restProps }) {
  return <TextLeft {...restProps}>{children}</TextLeft>;
};

Form.TextSmall = function FormTextSmall({ children, ...restProps }) {
  return <TextSmall {...restProps}>{children}</TextSmall>;
};

Form.Link = function FormLink({ children, ...restProps }) {
  return <Link {...restProps}>{children}</Link>;
};

Form.Input = function FormInput({ children, ...restProps }) {
  return <Input {...restProps}>{children}</Input>;
};

Form.InputNarrow = function FormInputNarrow({ children, ...restProps }) {
  return <InputNarrow {...restProps}>{children}</InputNarrow>;
};

Form.OptionButton = function FormOptionButton({ children, ...restProps }) {
  return <OptionButton {...restProps}>{children}</OptionButton>;
};

Form.OptionText = function FormOptionText({ children, ...restProps }) {
  return <OptionText {...restProps}>{children}</OptionText>;
};

Form.SectionHeader = function FormSectionHeader({ children, ...restProps }) {
  return <SectionHeader {...restProps}>{children}</SectionHeader>;
};

Form.Button = function FormButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};

Form.SubButton = function FormSubButton({ children, ...restProps }) {
  return <SubButton {...restProps}>{children}</SubButton>;
};

Form.BackButton = function FormBackButton({ children, ...restProps }) {
  return <BackButton {...restProps}>{children}</BackButton>;
};

Form.RadioBox = function FormRadioBox({ children, ...restProps }) {
  return <RadioBox {...restProps}>{children}</RadioBox>;
};

Form.CheckBox = function FormCheckBox({ children, ...restProps }) {
  return <CheckBox {...restProps}>{children}</CheckBox>;
};

Form.Body = function FormBody({ children, ...restProps }) {
  return <Body {...restProps}><span>{children}</span></Body>
};