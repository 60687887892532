import React, { useState, useEffect } from 'react';

import {
  AddButton,
  LineWrapper,
  TariffInput,
  NameInput,
  CategoryInput,
  PriceInput,
  RemoveButton
} from './styles/add-on-test';

export default function AddOnTest({ children, onTestsChange, ...restProps }) {
  const [addOnTests, setAddOnTests] = useState([]);

  const addTest = () => {
    setAddOnTests([...addOnTests, { tariffCode: '', name: '', category: '', price: '' }]);
  };

  const removeTest = (index) => {
    setAddOnTests(addOnTests.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    const updatedTests = [...addOnTests];
    updatedTests[index][field] = value;

    if (field === 'price') {
      const price = parseFloat(value); // Parse the price value
      if (!isNaN(price)) { 
        const vatRate = 15;
        const vatAmount = price / (1 + (vatRate / 100)) * (vatRate / 100);
        const exclusivePrice = price - vatAmount; 
        updatedTests[index].vat = vatAmount.toFixed(2); // Add vat to the object
        updatedTests[index].exclusive_price = exclusivePrice.toFixed(2); // Add exclusive_price
      } else {
        // Handle cases where the price is not a valid number
        updatedTests[index].vat = 0; 
        updatedTests[index].exclusive_price = 0; 
      }
    }
    
    setAddOnTests(updatedTests);
  };

  useEffect(() => {
    // Call the onItemsChange prop (if provided) whenever items change
    if (onTestsChange) {
      onTestsChange(addOnTests);
    }
  }, [addOnTests]);


  return (
    <div>
      {addOnTests.map((test, index) => (
        <div key={index}> 
          <LineWrapper> {/* Wrap CategoryInput */}
            <AddOnTest.CategoryInput 
              value={test.category} 
              onChange={(e) => handleInputChange(index, 'category', e.target.value)} 
            />
          </LineWrapper>
          <LineWrapper> {/* Wrap other inputs */}
            <AddOnTest.TariffInput 
              value={test.tariffCode} 
              onChange={(e) => handleInputChange(index, 'tariffCode', e.target.value)} 
            />
            <AddOnTest.NameInput 
              value={test.name} 
              onChange={(e) => handleInputChange(index, 'name', e.target.value)} 
            />
            <AddOnTest.PriceInput 
              value={test.price} 
              onChange={(e) => handleInputChange(index, 'price', e.target.value)} 
            />
            <AddOnTest.RemoveButton onClick={() => removeTest(index)} /> 
          </LineWrapper>
        </div>
      ))}
      <AddOnTest.AddButton onClick={addTest} />
    </div>
  );
}

AddOnTest.AddButton = function AddOnTestAddButton({ onClick, ...restProps }) {
  return <AddButton onClick={onClick} {...restProps}>+</AddButton>;
};

AddOnTest.TariffInput = function AddOnTestTariffInput({ value, onChange, ...restProps }) {
  return (
    <TariffInput 
      type="text" 
      placeholder="T.C." 
      value={value} 
      onChange={onChange} 
      {...restProps} 
    />
  );
};

AddOnTest.NameInput = function AddOnTestNameInput({ value, onChange, ...restProps }) {
  return (
    <NameInput 
      type="text" 
      placeholder="Name" 
      value={value} 
      onChange={onChange} 
      {...restProps} 
    />
  );
};

AddOnTest.CategoryInput = function AddOnTestCategoryInput({ value, onChange, ...restProps }) {
  return (
    <CategoryInput 
      type="text" 
      placeholder="Category" 
      value={value} 
      onChange={onChange} 
      {...restProps} 
    />
  );
};

AddOnTest.PriceInput = function AddOnTestPriceInput({ value, onChange, ...restProps }) {
  return (
    <PriceInput 
      type="text" 
      placeholder="Price" 
      value={value} 
      onChange={onChange} 
      {...restProps} 
    />
  );
};

AddOnTest.RemoveButton = function AddOnTestRemoveButton({ onClick, ...restProps }) {
  return <RemoveButton onClick={onClick} {...restProps}>-</RemoveButton>;
};