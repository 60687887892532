import { getFirestore, doc, runTransaction } from 'firebase/firestore';

export default async function deleteIncompleteOrder(practiceId, id) {
  try {
    const db = getFirestore();

    await runTransaction(db, async (transaction) => {
      const docRef = doc(db, 'incomplete_orders', practiceId);
      const docSnap = await transaction.get(docRef);

      if (docSnap.exists()) {
        let existingData = docSnap.data().incompleteTests;

        // Find the index of the order to delete
        const orderIndex = existingData.findIndex(order => order.id === id);

        if (orderIndex !== -1) {
          // Remove the order from the array
          existingData.splice(orderIndex, 1); 

          transaction.set(docRef, { incompleteTests: existingData }, { merge: true });
        } else {
          console.log('Incomplete order not found for deletion:', id);
        }
      } else {
        console.log('No incomplete orders found for practice:', practiceId);
      }
    });

    console.log('Incomplete order deleted for practice:', practiceId, 'with ID:', id);
    return true;
  } catch (error) {
    console.error('Error deleting incomplete order:', error);
    return false;
  }
}